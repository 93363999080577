import React, { useEffect } from 'react';

import initialGa from '../../utils/GoogleAnalytcs';

import Title from '../../components/Title';
import EviteFilas from '../../components/EviteFilas';
import ServicosParcelamentosInfo from '../../components/ServicosParcelamentosInfo';
import ServicoPagDebito from '../../components/ServicoPagDebito';
import VenhaSerParceiro from '../../components/VenhaSerParceiro';

import './styles.css';

import liberdadeHome from '../../assets/liberdadeHome.jpeg';

function Home() {
  useEffect(() => {
    window.scroll(0, 0);
    initialGa('/home');
  }, []);

  return (
    <>
      <Title
        title="Precisa parcelar um boleto? Ganhe fôlego financeiro com a HelpManey
      e não fique no vermelho"
        img={liberdadeHome}
        typeImg="imagem"
      />
      <div className="container">
        <EviteFilas />
        <ServicosParcelamentosInfo />
        <ServicoPagDebito />
        <VenhaSerParceiro />
      </div>
    </>
  );
}

export default Home;
