import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import initGa from '../../utils/GoogleAnalytcs';

import Title from '../../components/Title';

import './styles.css';

import contas from '../../assets/contas.jpg';

export default function QuemSomos() {
  useEffect(() => {
    window.scroll(0, 0);
    initGa('/quemsomos');
  }, []);
  return (
    <>
      <Title
        img={contas}
        title="Não esquente a cabeça com dividas atrasadas"
        typeImg="imagem"
      />
      <div className="quem-somos">
        <div className="centro">
          <p>
            A HelpManey tem como objetivo principal ajudar pessoas com
            problemas financeiros, queremos proporcionar uma forma de você
            equilibrar suas finanças de forma justa, segura
            e sem burocracia.
          </p>
          <p>
            Você pode pagar suas contas no conforto do seu lar, é prático é seguro,
            não passe dificuldades, passe o cartão e ganhe folego financeiro,
            nós pagamos e parcelamos em até 12 vezes através do cartão de crédito.
          </p>
          <button className="btn-padrao" type="button">
            <Link to="/comofunciona">Clique aqui para ver como funciona</Link>
          </button>
        </div>
      </div>

    </>
  );
}
