import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from './pages/Home';
import SobreNos from './pages/SobreNos';
import ComoFunciona from './pages/ComoFunciona';
import PagueFacil from './pages/PagueFacil';
// import PagueDebito from './pages/PagueDebito';
import Simular from './pages/Simular';
import SejaParceiro from './pages/SejaParceiro';


export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/sobrenos" component={SobreNos} />
      <Route path="/comofunciona" component={ComoFunciona} />
      <Route path="/paguefacil" component={PagueFacil} />
      {/* <Route path="/paguedebito" component={PagueDebito} /> */}
      <Route path="/simular" component={Simular} />
      <Route path="/sejaparceiro" component={SejaParceiro} />
      <Route component={Home} />
    </Switch>
  );
}
