import React from 'react';
import Lottie from 'react-lottie';
import { Link } from 'react-router-dom';

import './styles.css';

import hipercard from '../../assets/Hipercard.png';
import mastercard from '../../assets/mastercard.png';
import visa from '../../assets/visa.png';
import elo from '../../assets/elo.png';

import caraSentado from '../../animations/carasentado.json';

function EviteFilas() {
  return (
    <div className="evite">
      <div className="container-column">
        <div className="animacao">
          <Lottie
            options={{
              animationData: caraSentado,
              autoplay: true,
              loop: true,
              rendererSettings: 'xMidYMid slice',
            }}
            height={400}
            width={400}
          />
        </div>
        <p>
          Evite filas em bancos e lotéricas,
          aqui você tem comodidade. Parcele suas contas em até
          <strong> 12x no cartão de crédito </strong>
          sem sair de casa.
        </p>
      </div>
      <div className="bandeiras">
        <img src={mastercard} alt="" className="bandeira" />
        <img src={elo} alt="" className="bandeira" />
        <img src={hipercard} alt="" className="bandeira" />
        <img src={visa} alt="" className="bandeira" />
      </div>
      <button className="btn-padrao" type="button">
        <Link to="/comofunciona">Saiba mais</Link>
      </button>
    </div>
  );
}

export default EviteFilas;
