export default function verificaCaracteresEspeciais(string) {
  let verifcacao = false;
  const caracteresEspeciais = ['$', '%', '&', '*', '#'];
  caracteresEspeciais.forEach((letra) => {
    if (string.includes(letra)) {
      verifcacao = true;
    }
  });
  return verifcacao;
}
