import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import initialGa from '../../utils/GoogleAnalytcs';

import Title from '../../components/Title';

import './styles.css';
import somando from '../../assets/somando.jpg';

function ComoFunciona() {
  useEffect(() => {
    window.scroll(0, 0);
    initialGa('/comofunciona');
  }, []);


  return (
    <>
      <Title
        img={somando}
        title="Simples, sem burocracia é Pague Fácil!"
        typeImg="imagem"
      />
      <div className="como-funciona">
        <div className="explicacao">
          <h4>
            Possui boletos atrasados, IPVA, IPTU, fatura de cartão ou alguma
            outra conta que não vai conseguir pagar, ou quer um fôlego nas
            finanças?
          </h4>
          <p>
            Temos uma maneira simples de resolver seu problema, você só precisa
            ter cartão de crédito e limite disponível, parcelamos em até 12 vezes.
            Clique no campo
            {' '}
            <Link to="/paguefacil">Pague Aqui</Link>
            {' '}
            preencha o formulário com seus dados e envie uma foto ou um
            PDF de sua conta, que entraremos em contato ou se preferir nos chamar no
            whats clique na logo do whats no canto inferior direito.
          </p>
          <p>Estamos pronto para te ajudar.</p>
          <p>
            Se quiser fazer uma simulação sem compromisso, clique no botão abaixo
          </p>
        </div>
        <button className="btn-padrao" type="button">
          <Link to="/simular">Simular</Link>
        </button>
      </div>
    </>
  );
}

export default ComoFunciona;
