import React, { useRef } from 'react';

import './styles.css';

import lixeira from '../../assets/lixeira.svg';

import { useData } from '../../contexts/Data';
import { useError } from '../../contexts/Error';

function Arquivos() {
  const { files, setFiles } = useData();
  const { setMsgErrorFiles, msgErrorFiles } = useError();

  const filesInput = useRef();

  function excluirFile(file) {
    const newArray = files.filter((f) => f.name !== file.name);
    setFiles(newArray);
  }

  function chooseFile() {
    filesInput.current.click();
  }

  function pegarArquivos(e) {
    const { files: filesSelect } = e.target;
    const types = [
      'image/jpeg',
      'image/pjpeg',
      'image/png',
      'application/pdf',
    ];

    const filesTemp = [];

    for (let i = 0; i < filesSelect.length; i++) {
      if (types.includes(filesSelect[i].type)) {
        filesTemp.push(filesSelect[i]);
      } else {
        setMsgErrorFiles('Os arquivos que não são pdf, png ou jpg, não foram adicionados, pois não são aceitos');
      }
    }

    setFiles([...files, ...filesTemp]);
  }

  return (
    <div className="arquivos container-column">
      <h3>Selecione imagen(s) de seu boleto ou um pdf</h3>
      <div className="form-arquivos ">
        <div className="files">
          {files.map((file) => (
            <div className="file" key={file.name}>
              <p>{file.name}</p>
              <button
                type="button"
                onClick={() => excluirFile(file)}
              >
                <img src={lixeira} alt="apagar" />
              </button>
            </div>
          ))}
        </div>
        <button
          type="button"
          onClick={chooseFile}
          id="choose-file"
        >
          Escolher arquivo(s)
        </button>
        <input
          type="file"
          ref={filesInput}
          name="arquivo"
          accept="image/png, image/jpg, application/pdf"
          multiple
          onChange={pegarArquivos}
        />

      </div>
      <p className="msg-error">
        {msgErrorFiles}
      </p>
    </div>
  );
}

export default Arquivos;
