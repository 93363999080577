import React from 'react';
import Lottie from 'react-lottie';

import { useLoading } from '../../contexts/Loading';
import { useData } from '../../contexts/Data';
import { useError } from '../../contexts/Error';

import './styles.css';

import Load from '../../animations/load.json';
import Sucesso from '../../animations/sucesso.json';

function Loading({ type }) {
  const {
    modalRef, loadRef, loading, success, setSuccess, contentRef,
  } = useLoading();

  const {
    nome, email, tel, valor, setNome, setEmail, setConfirmaEmail, setTel, setFiles, setValor,
  } = useData();

  const { msgError } = useError();
  return (
    <div className="modal-success invisible" ref={modalRef}>
      <div className="load invisible" ref={loadRef}>
        {loading ? (
          <Lottie
            options={{
              animationData: Load,
              autoplay: true,
              loop: true,
              rendererSettings: 'xMidYMid slice',
            }}
            height={200}
            width={200}
          />
        ) : (<div />)}
        {success ? (
          <Lottie
            options={{
              animationData: Sucesso,
              autoplay: true,
              loop: false,
              rendererSettings: 'xMidYMid slice',
            }}
            height={200}
            width={200}
            eventListeners={[
              {
                eventName: 'complete',
                callback: () => {
                  setTimeout(() => {
                    setSuccess(false);
                    contentRef.current.classList.remove('invisible');
                    loadRef.current.classList.add('invisible');
                  }, 500);
                },
              },
            ]}
          />
        ) : (<div />)}
      </div>
      {msgError ? (
        <div className="erro-envio">
          <p>
            {msgError}
          </p>
          <button
            type="button"
            className="btn-padrao"
            onClick={() => {
              contentRef.current.classList.add('invisible');
              modalRef.current.classList.add('invisible');
            }}
          >
            OK
          </button>

        </div>
      ) : (<div />)}
      { type === 'conta' ? (
        <div className="content invisible" ref={contentRef}>
          <h3>Dados da solicitação</h3>
          <p>{`Nome: ${nome}`}</p>
          <hr />
          <p>{`Email: ${email}`}</p>
          <hr />
          <p>{`Telefone: ${tel}`}</p>
          <hr />
          <p>{`Valor: R$ ${Number(valor).toFixed(2)}`}</p>
          <hr />
          <h4>Em breve entraremos em contato</h4>
          <button
            type="button"
            className="btn-padrao"
            onClick={() => {
              contentRef.current.classList.add('invisible');
              modalRef.current.classList.add('invisible');
              setNome('');
              setEmail('');
              setConfirmaEmail('');
              setTel('');
              setFiles([]);
              setValor('');
            }}
          >
            OK
          </button>
        </div>
      ) : (
        <div className="content invisible" ref={contentRef}>
          <h4>Em breve entraremos em contato</h4>
          <button
            type="button"
            className="btn-padrao"
            onClick={() => {
              contentRef.current.classList.add('invisible');
              modalRef.current.classList.add('invisible');
            }}
          >
            OK
          </button>
        </div>
      )}

    </div>
  );
}

export default Loading;
