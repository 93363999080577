import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import Routes from './routes';

import './App.css';

import Footer from './components/Footer';
import Header from './components/Header';
import ButtonWhats from './components/ButtonWhats';
// import ButtonBaixeApp from './components/ButtonBaixeApp';

import DataProvider from './contexts/Data';
import ErrorProvider from './contexts/Error';
import LoadingProvider from './contexts/Loading';


function App() {
  return (
    <BrowserRouter>
      <Header />
      <DataProvider>
        <ErrorProvider>
          <LoadingProvider>
            <Routes />
          </LoadingProvider>
        </ErrorProvider>
      </DataProvider>
      {/* <ButtonBaixeApp /> */}
      <ButtonWhats />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
