import React, {
  createContext, useState, useContext, useRef,
} from 'react';

const ErrorContext = createContext();

export default function DadosProvider({ children }) {
  const [msgError, setMsgError] = useState('');

  const inputNomeRef = useRef();
  const inputTelRef = useRef();
  const inputEmailRef = useRef();
  const inputValorRef = useRef();
  const inputCpfRef = useRef();
  const inputCepRef = useRef();
  const inputRuaRef = useRef();
  const inputNumRef = useRef();
  const inputBairroRef = useRef();
  const inputCidadeRef = useRef();
  const inputUfref = useRef();


  return (
    <ErrorContext.Provider value={{
      inputNomeRef,
      inputTelRef,
      inputEmailRef,
      inputValorRef,
      inputCpfRef,
      inputCepRef,
      inputRuaRef,
      inputNumRef,
      inputBairroRef,
      inputCidadeRef,
      inputUfref,
      msgError,
      setMsgError,
    }}
    >
      {children}
    </ErrorContext.Provider>
  );
}

export function useError() {
  const context = useContext(ErrorContext);

  if (!context) throw new Error('useError must be used whitin a DataProvider');

  const {
    inputNomeRef,
    inputTelRef,
    inputEmailRef,
    inputValorRef,
    inputCpfRef,
    inputCepRef,
    inputRuaRef,
    inputNumRef,
    inputBairroRef,
    inputCidadeRef,
    inputUfref,
    msgError,
    setMsgError,
  } = context;

  return {
    inputNomeRef,
    inputTelRef,
    inputEmailRef,
    inputValorRef,
    inputCpfRef,
    inputCepRef,
    inputRuaRef,
    inputNumRef,
    inputBairroRef,
    inputCidadeRef,
    inputUfref,
    msgError,
    setMsgError,
  };
}
