import React from 'react';

import './styles.css';

function Title({ title, img, typeImg }) {
  return (
    <>
      <h1 id="page-title">
        {title}
      </h1>
      <img src={img} alt="logo" id={typeImg} />
    </>
  );
}

export default Title;
