import React, { useRef } from 'react';
import initGa from '../../utils/GoogleAnalytcs';

import './styles.css';

import whatsLogo from '../../assets/whatslogo.png';

function ButtonWhats() {
  const refWhats = useRef();

  function openWhats() {
    refWhats.current.classList.add('open');
  }

  function closeWhats() {
    refWhats.current.classList.remove('open');
  }
  return (
    <button
      type="button"
      className="whats"
      ref={refWhats}
      onMouseEnter={openWhats}
      onMouseLeave={closeWhats}
    >
      <a
        target="__blank"
        href="https://api.whatsapp.com/send?phone=5551993796953"
        onClick={() => {
          initGa('/abrirwhats');
        }}
      >
        <img src={whatsLogo} alt="whats" />
        <p>Chamar no Whatsapp</p>
      </a>
    </button>
  );
}

export default ButtonWhats;
