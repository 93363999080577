import React, { useState, useEffect } from 'react';
import Slider from 'react-slider';

import './styles.css';

import { useData } from '../../contexts/Data';
import { useError } from '../../contexts/Error';
import api from '../../services/api';

function FormContaParcelado() {
  const {
    valor, setValor, vezes, setVezes, setInstallments, installments,
  } = useData();

  const [parcela, setParcela] = useState('');

  const { inputValorRef, msgErrorValores } = useError();

  useEffect(() => {
    if (valor === 0 || valor === '') {
      setParcela(0);
    } else {
      const installment = installments.find((i) => i.installments === vezes);
      if (installment) {
        setParcela(installment.installmentValue);
      }
    }
  }, [valor, vezes, installments]);

  useEffect(() => {
    (async () => {
      if (valor) {
        const { data } = await api.post('/api/web/tickets/simulation', {
          type: 'ticket',
          amountSale: Number(valor),
        });
        setInstallments([...data.tableInstallments]);
      }
    })();
  }, [valor]);

  return (
    <div className="simulador container-column">
      <h3>Preencha os valores</h3>
      <div className="form-simulador">
        <div className="container-input">
          <p>Valor</p>
          <input
            type="Number"
            placeholder="Valor ..."
            value={valor}
            ref={inputValorRef}
            onChange={(e) => setValor(e.target.value)}
          />
        </div>
        <div className="container-input">
          <p>Quantas Vezes</p>
          <Slider
            className="slider"
            min={4}
            max={12}
            step={1}
            defaultValue={4}
            onChange={(e) => setVezes(e)}
            value={vezes}
            thumbClassName="thumb"
            trackClassName="track"
            renderThumb={(props) => <div {...props} />}
          />
          <div className="num-vezes">
            <p>4</p>
            <p>12</p>
          </div>
        </div>
        <div className="resultado">
          <p className="vezes">{`${vezes}x de `}</p>
          <p className="parcela">{`R$ ${Number.parseFloat(parcela).toFixed(2)}`}</p>
        </div>
      </div>
      <p className="error">
        {msgErrorValores}
      </p>
    </div>
  );
}

export default FormContaParcelado;
