import React from 'react';
import { Link } from 'react-router-dom';

import './styles.css';

import Debito from '../../assets/pag-debito.jpg';

function ServicoPagDebito() {
  return (
    <div className="pag-debito">
      <img src={Debito} alt="Debito" />
      <div className="text">
        <h1>Pague suas contas à vista no débito</h1>
        <p>
          Com a gente você pode pagar boletos sem precisar sair de casa e
          enfrentar filas enormes em bancos e lotéricas
        </p>
        <button
          className="btn-padrao"
          type="button"
        >
          <Link to="/paguedebito">
            Saiba Mais
          </Link>
        </button>
      </div>
    </div>
  );
}

export default ServicoPagDebito;
