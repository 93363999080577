import React from 'react';

import './styles.css';

import Menu from '../Menu';

import BackHeader from '../../assets/header.svg';
import newLogo from '../../assets/newlogo.svg';

export default function Header({ img }) {
  return (
    <header className="menu-header">
      <img src={BackHeader} alt="background" id="background" />
      <a href="https://www.helpmaney.com.br">
        <div className="nome-help">
          <img src={newLogo} alt="background" id="logo-h" />
          <p className="help">Help</p>
          <p className="maney">Maney</p>
        </div>
      </a>
      <Menu />
    </header>
  );
}
