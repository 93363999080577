import React, {
  createContext, useState, useContext, useRef,
} from 'react';

const LoadingContext = createContext();

export default function DadosProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);


  const contentRef = useRef();

  const modalRef = useRef();
  const loadRef = useRef();
  return (
    <LoadingContext.Provider value={{
      loading,
      setLoading,
      success,
      setSuccess,
      contentRef,
      modalRef,
      loadRef,
    }}
    >
      {children}
    </LoadingContext.Provider>
  );
}

export function useLoading() {
  const context = useContext(LoadingContext);

  if (!context) throw new Error('useLoading must be used whitin a DataProvider');

  const {
    loading,
    setLoading,
    success,
    setSuccess,
    contentRef,
    modalRef,
    loadRef,

  } = context;

  return {
    loading,
    setLoading,
    success,
    setSuccess,
    contentRef,
    modalRef,
    loadRef,

  };
}
