import React, {
  createContext, useState, useContext, useEffect,
} from 'react';
import simular from '../utils/Calculo';

const DataContext = createContext();

export default function DadosProvider({ children }) {
  const [nome, setNome] = useState('');
  const [tel, setTel] = useState('');
  const [email, setEmail] = useState('');
  const [confirmaEmail, setConfirmaEmail] = useState('');
  const [valor, setValor] = useState('');
  const [vezes, setVezes] = useState(4);
  const [parcela, setParcela] = useState(0);
  const [installments, setInstallments] = useState([]);
  const [files, setFiles] = useState([]);
  const [documento, setDocumento] = useState();
  const [pesquisa, setPesquisa] = useState();
  const [cep, setCep] = useState();
  const [cpf, setCpf] = useState();
  const [rua, setRua] = useState();
  const [num, setNum] = useState();
  const [bairro, setBairro] = useState();
  const [cidade, setCidade] = useState();
  const [uf, setUf] = useState();


  useEffect(() => {
    if (valor === 0 || valor === '') {
      setParcela(0);
    } else {
      setParcela(simular(valor, vezes));
    }
  }, [valor, vezes]);

  return (
    <DataContext.Provider value={{
      nome,
      setNome,
      tel,
      setTel,
      email,
      setEmail,
      confirmaEmail,
      setConfirmaEmail,
      valor,
      setValor,
      vezes,
      setVezes,
      parcela,
      setParcela,
      files,
      setFiles,
      pesquisa,
      setPesquisa,
      cep,
      setCep,
      cpf,
      setCpf,
      rua,
      setRua,
      num,
      setNum,
      bairro,
      setBairro,
      cidade,
      setCidade,
      uf,
      setUf,
      documento,
      setDocumento,
      installments,
      setInstallments,
    }}
    >
      {children}
    </DataContext.Provider>
  );
}

export function useData() {
  const context = useContext(DataContext);
  if (!context) throw new Error('useData must be used whitin a DataProvider');
  const {
    nome,
    setNome,
    tel,
    setTel,
    email,
    setEmail,
    confirmaEmail,
    setConfirmaEmail,
    valor,
    setValor,
    vezes,
    setVezes,
    parcela,
    setParcela,
    files,
    setFiles,
    pesquisa,
    setPesquisa,
    cep,
    setCep,
    cpf,
    setCpf,
    rua,
    setRua,
    num,
    setNum,
    bairro,
    setBairro,
    cidade,
    setCidade,
    uf,
    setUf,
    documento,
    setDocumento,
    installments,
    setInstallments,
  } = context;

  return {
    nome,
    setNome,
    tel,
    setTel,
    email,
    confirmaEmail,
    setConfirmaEmail,
    setEmail,
    valor,
    setValor,
    vezes,
    setVezes,
    parcela,
    setParcela,
    files,
    setFiles,
    pesquisa,
    setPesquisa,
    cep,
    setCep,
    cpf,
    setCpf,
    rua,
    setRua,
    num,
    setNum,
    bairro,
    setBairro,
    cidade,
    setCidade,
    uf,
    setUf,
    documento,
    setDocumento,
    installments,
    setInstallments,
  };
}
