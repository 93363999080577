import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import initialGa from '../../utils/GoogleAnalytcs';

import DataProvider from '../../contexts/Data';

import Title from '../../components/Title';
import SimuladorParcelado from '../../components/SimuladorParcelado';

import simulando from '../../assets/simulando.jpg';

import './styles.css';

export default function Simular() {
  useEffect(() => {
    window.scroll(0, 0);
    initialGa('/simular');
  }, []);

  return (
    <>
      <Title
        img={simulando}
        title="Simule à vontade, até encontrar a melhor forma para suas finanças"
        typeImg="imagem"
      />
      <div className="container-simulador container-column">
        <DataProvider>
          <SimuladorParcelado />
        </DataProvider>
        <button type="button" className="btn-padrao pague">
          <Link to="/paguefacil">
            Pague Aqui
          </Link>
        </button>
      </div>
    </>
  );
}
