import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import './styles.css';

export default function Menu() {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef();

  function menuToogle() {
    const element = menuRef.current;
    if (showMenu) {
      element.classList.remove('on');
      setShowMenu(false);
    } else {
      element.classList.add('on');
      setShowMenu(true);
    }
  }
  return (
    <div ref={menuRef} className="menu-section">
      <nav className="menu">
        <ul>
          <li><Link to="/sobrenos" onClick={menuToogle}>Sobre Nós</Link></li>
          <li><Link to="/comofunciona" onClick={menuToogle}>Como Funciona</Link></li>
          <li><Link to="/simular" onClick={menuToogle}>Simular</Link></li>
          <li><Link to="/sejaparceiro" onClick={menuToogle}>Seja Parceiro</Link></li>
          {/* <li><Link to="/paguedebito"
          onClick={menuToogle} id="pague">Pague à vista</Link></li> */}
          <li><Link to="/paguefacil" onClick={menuToogle} id="pague">Parcele Aqui</Link></li>
        </ul>
      </nav>
      <button type="button" className="menu-toogle" onClick={menuToogle}>
        <div className="one" />
        <div className="two" />
        <div className="three" />
      </button>
    </div>
  );
}
