import React from 'react';
import { Link } from 'react-router-dom';

import './styles.css';

// import Phone from '../../assets/phone.svg';
// import playStore from '../../assets/play-store.svg';
// import appleStore from '../../assets/apple-store.svg';
import facebook from '../../assets/fb.png';
import instagram from '../../assets/instagram.png';
import enviar from '../../assets/email.png';
import ligar from '../../assets/fone.png';
import lugar from '../../assets/lugar.png';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="flex-footer">
        <div className="menu">
          <ul>
            <li><Link to="/sobrenos">Sobre nós</Link></li>
            <hr />
            <li><Link to="/comofunciona">Como Funciona</Link></li>
            <hr />
            <li><Link to="/simular">Simular</Link></li>
            <hr />
            <li><Link to="/sejaparceiro">Seja Parceiro</Link></li>
            <hr />
            <li><Link to="/paguefacil">Pague aqui</Link></li>
            <hr />
          </ul>
        </div>
        <div className="contato">
          <div className="cont">
            <img src={facebook} id="icon" alt="facebook" />
            <a
              href="https://www.facebook.com/HelpManey-103118461467717/?view_public_for=103118461467717"
              target="__blank"
            >
              Help Maney
            </a>
          </div>
          <hr />
          <div className="cont">
            <img src={instagram} id="icon" alt="facebook" />
            <a href="https://www.instagram.com/helpmaney/?hl=pt-br" target="__blank">@helpmaney</a>
          </div>
          <hr />
          <div className="cont">
            <img src={enviar} id="icon" alt="email" />
            <p>atendimento.helpmaney@gmail.com</p>
          </div>
          <hr />
          <div className="cont">
            <img src={ligar} id="icon" alt="ligar" />
            <p>051 9 93796953</p>
          </div>
        </div>
        <div className="endereco">
          <h3>Help Maney Pague Fácil</h3>
          <div className="end">
            <img src={lugar} alt="lugar" />
            <div className="text">
              <p>Rua Major Bento Alves, 2225  </p>
              <p>Bairro Amaral Ribeiro, Sapiranga/RS</p>
              <p>CEP: 93800-000</p>
            </div>
          </div>
        </div>
        {/* <div className="baixe-app">
          <div>
            <h3>Baixe nosso App</h3>
            <a href="https://play.google.com/store/apps/details?id=com.helpmaney.mobile">
              <img src={playStore} alt="Play store" className="loja" />
            </a>

          </div>
        </div> */}
      </div>
    </footer>
  );
}
