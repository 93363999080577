import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import verificaCaracteresEspeciais from '../../utils/VerificarCaracteresEspeciais';
import initialGa from '../../utils/GoogleAnalytcs';

import Loading from '../../components/Loading';
import Title from '../../components/Title';

import './styles.css';

import { useLoading } from '../../contexts/Loading';

import Parceria from '../../assets/parceria.png';

export default function SejaParceiro() {
  const [razao, setRazao] = useState('');
  const [razaoError, setRazaoError] = useState(false);
  const [telefone, setTelefone] = useState('');
  const [telError, setTelError] = useState(false);
  const [email, setEmail] = useState('');
  const [confirmaEmail, setConfirmaEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [msgError, setMsgError] = useState('');

  const {
    modalRef, loadRef, setLoading, setSuccess,
  } = useLoading();

  useEffect(() => {
    window.scroll(0, 0);
    initialGa('/sejaparceiro');
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    if (!razao) {
      setRazaoError(true);
      setMsgError('Preencha o campo com sua razao');
      return;
    }
    if (verificaCaracteresEspeciais(razao)) {
      setRazaoError(true);
      setMsgError('Use apenas letras, não pode (#, $, %, &, *)');
      return;
    }

    setRazaoError(false);

    if (!telefone) {
      setTelError(true);
      setMsgError('Preencha o campo com seu telefone');
      return;
    }

    if (verificaCaracteresEspeciais(telefone)) {
      setMsgError('Use apenas letras, não pode (#, $, %, &, *)');
      setTelError(true);
      return;
    }

    setTelError(false);

    if (!email) {
      setEmailError(true);
      setMsgError('Preencha o campo com seu email');
      return;
    }

    if (verificaCaracteresEspeciais(email)) {
      setEmailError(true);
      setMsgError('Use apenas letras, não pode (#, $, %, &, *)');
      return;
    }
    if (email !== confirmaEmail) {
      setEmailError(true);
      setMsgError('Emails não batem');
      return;
    }

    setEmailError(false);

    setMsgError('');

    modalRef.current.classList.remove('invisible');
    loadRef.current.classList.remove('invisible');

    setLoading(true);
    try {
      await api.post('/parceiro', { razao, telefone, email }, {
        headers: { hpmkey: process.env.REACT_APP_API_KEY },
      });
    } catch (error) {
      setMsgError('Houve um erro de conexão, espera uns segundos e tente novamente ');
      setLoading(false);
      return;
    }

    setLoading(false);
    setSuccess(true);
  }

  return (
    <>
      <Title
        img={Parceria}
        title="Seja nosso parceiro comercial"
        typeImg="imagem"
      />
      <div className="container-parceiro container-column">
        <div className="explicacao container-column">
          <h5>
            Quer agregar mais fluxo ao seu comércio e ainda lucrar com a HelpManey?
          </h5>
          <p>
            Então não perca tempo, preencha o formulário que entraremos em contato com você.
          </p>
        </div>
        <form className="container-column" onSubmit={handleSubmit}>
          <div className="form-parceiro container-column">
            <div className="container-input">
              <p className={razaoError ? 'error' : ''}>Razão Social *</p>
              <input
                type="text"
                value={razao}
                onChange={(e) => setRazao(e.target.value)}
                className={razaoError ? 'error' : ''}
                placeholder="Razão social ..."
              />
            </div>
            <div className="container-input">
              <p className={telError ? 'error' : ''}>Telefone *</p>
              <input
                type="text"
                value={telefone}
                className={telError ? 'error' : ''}
                onChange={(e) => setTelefone(e.target.value)}
                placeholder="Telefone ..."
              />
            </div>
            <div className="container-input">
              <p className={emailError ? 'error' : ''}>Email *</p>
              <input
                type="email"
                value={email}
                className={emailError ? 'error' : ''}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email ..."
              />
            </div>
            <div className="container-input">
              <p className={emailError ? 'error' : ''}>Confirmar Email *</p>
              <input
                type="email"
                value={confirmaEmail}
                className={emailError ? 'error' : ''}
                onChange={(e) => setConfirmaEmail(e.target.value)}
                placeholder="Confirmar Email ..."
              />
            </div>
          </div>
          <button className="btn-padrao" type="submit">Enviar</button>
          <p className="msg-error">
            {msgError}
          </p>
        </form>
        <Loading />
      </div>
    </>
  );
}
