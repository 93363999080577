import React from 'react';

import './styles.css';

function Termos({ termoRef }) {
  return (
    <div className="termos">
      <input type="checkbox" name="termo" ref={termoRef} />
      <section className="texto">
        <p>Li e aceito os </p>
        <a href="https://termos-help.s3.amazonaws.com/Pol%C3%ADtica+de+Privacidade.pdf" target="_blank" rel="noopener noreferrer">
          termos de uso
        </a>
        <p>e de </p>
        <a href="https://termos-help.s3.amazonaws.com/Pol%C3%ADtica+de+Privacidade.pdf" target="_blank" rel="noopener noreferrer">
          politíca de privacidade
        </a>
      </section>
    </div>
  );
}

export default Termos;
