import React, { useRef } from 'react';


import './styles.css';

import Title from '../../components/Title';
import FormDadosUsuario from '../../components/FormDadosUsuario';
import SimuladorParcelado from '../../components/SimuladorParcelado';
import Arquivos from '../../components/Arquivos';
import Loading from '../../components/Loading';
import { useData } from '../../contexts/Data';
import { useError } from '../../contexts/Error';
import { useLoading } from '../../contexts/Loading';


import liberdade from '../../assets/liberdade.jpg';

import api from '../../services/api';
import Termos from '../../components/Termos';
import { setInvalidFormItem, setValidFormItem } from '../../utils/utils';


export default function PagueDebito() {
  const {
    nome,
    tel,
    email,
    valor,
    vezes,
    files,
    cpf,
    rua,
    num,
    bairro,
    cidade,
    uf,
    cep,
    installments,
  } = useData();

  const {
    msgError,
    setMsgError,
    inputNomeRef,
    inputTelRef,
    inputEmailRef,
    inputValorRef,
    inputCpfRef,
    inputCepRef,
    inputRuaRef,
    inputNumRef,
    inputBairroRef,
    inputCidadeRef,
    inputUfref,
  } = useError();

  const {
    modalRef, loadRef, setLoading, setSuccess,
  } = useLoading();

  const termoRef = useRef();


  async function handleSubmit(event) {
    event.preventDefault();
    setMsgError('');


    const refs = [inputNomeRef,
      inputTelRef,
      inputEmailRef,
      inputCpfRef,
      inputValorRef,
      inputCepRef,
      inputRuaRef,
      inputNumRef,
      inputBairroRef,
      inputCidadeRef,
      inputUfref];


    for (const ref of refs) {
      if (!ref.current.value || ref.current.value === '') {
        setInvalidFormItem(ref);
        return;
      }
      setValidFormItem(ref);
    }


    if (!termoRef.current.checked) {
      setMsgError('Aceite os termos antes de continuar');
      return;
    }

    modalRef.current.classList.remove('invisible');
    loadRef.current.classList.remove('invisible');

    setLoading(true);
    const body = new FormData();
    body.append('amountTicket', valor);
    body.append('customer', JSON.stringify({
      name: nome,
      phoneNumber: tel,
      email,
      document: cpf,
      address: rua,
      number: num,
      neighborhood: bairro,
      city: cidade,
      state: uf,
      cep,
    }));
    files.forEach((file) => body.append('tickets', file));


    try {
      const {
        amountReceived, amountTotal,
        installmentValue,
      } = installments.find((i) => i.installments === vezes);

      let response = await api.post('/api/web/transactions', {
        type: 'ticket',
        amountTotalTransaction: amountTotal,
        amountReceived,
        amountSale: valor,
        amountInstallments: vezes,
        amountPerInstallment: installmentValue,
        companyId: process.env.REACT_APP_HELPMANEY,
      });


      if (response.status !== 201) {
        setLoading(false);
        setMsgError('Houve um erro ao enviar a solicitação, aguarde uns segundos e tente novamente.');
        return;
      }

      body.append('transactionId', response.data.id);

      response = await api.post('/api/web/tickets', body);


      if (response.status !== 201) {
        setLoading(false);
        setMsgError('Houve um erro ao enviar a solicitação, aguarde uns segundos e tente novamente.');
        return;
      }

      setLoading(false);
      setSuccess(true);
    } catch (error) {
      setLoading(false);
      setMsgError('Houve um erro ao enviar a solicitação, aguarde uns segundos e tente novamente.');
    }
  }

  return (
    <>
      <Title
        img={liberdade}
        title="Quer um fôlego financeiro? Estamos aqui pra te ajudar, e você pode fazer tudo da sua casa"
        typeImg="imagem"
      />
      <form className="form-parcelado">
        <FormDadosUsuario />
        <SimuladorParcelado />
        <Arquivos />
        <button
          type="submit"
          className="btn-submit btn-padrao"
          onClick={handleSubmit}
        >
          Enviar
        </button>
        <Termos termoRef={termoRef} />
        <p className="msg-error">
          {msgError}
        </p>
      </form>
      <Loading type="conta" />
    </>
  );
}
