import React from 'react';
import { Link } from 'react-router-dom';

import './styles.css';

import Parceria from '../../assets/parceria.png';

function VenhaSerParceiro() {
  return (
    <div className="parceria">
      <div>
        <p>Venha ser nosso parceiro comercial</p>
        <button className="btn-padrao" type="button">
          <Link to="/sejaparceiro">
            Saiba Mais
          </Link>
        </button>
      </div>
      <img src={Parceria} alt="Parceria" />
    </div>
  );
}

export default VenhaSerParceiro;
