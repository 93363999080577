import React from 'react';

import './styles.css';

import { useData } from '../../contexts/Data';
import { useError } from '../../contexts/Error';


function FormDadosUsuario() {
  const {
    nome,
    setNome,
    tel,
    setTel,
    email,
    setEmail,
    cep,
    setCep,
    cpf,
    setCpf,
    rua,
    setRua,
    num,
    setNum,
    bairro,
    setBairro,
    cidade,
    setCidade,
    uf,
    setUf,
  } = useData();

  const {
    nomeError,
    telError,
    emailError,
    cpfError,
    ruaError,
    numError,
    bairroError,
    cidadeError,
    ufError,
    msgErrorDados,
    inputNomeRef,
    inputCpfRef,
    inputTelRef,
    inputEmailRef,
    inputCepRef,
    inputRuaRef,
    inputNumRef,
    inputBairroRef,
    inputCidadeRef,
    inputUfref,
  } = useError();

  return (
    <>
      <div className="dados container-column">
        <h3>Preencha os dados</h3>
        <div className="form container-column">
          <div className="row">
            <div className="container-input">
              <p className={nomeError ? 'error' : ''}>Nome *</p>
              <input
                type="name"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                placeholder="Nome Completo ..."
                className={nomeError ? 'error' : ''}
                ref={inputNomeRef}
              />
            </div>
            <div className="container-input">
              <p className={cpfError ? 'error' : ''}>CPF *</p>
              <input
                type="name"
                value={cpf}
                onChange={(e) => setCpf(e.target.value)}
                placeholder="CPF"
                className={cpfError ? 'error' : ''}
                ref={inputCpfRef}
              />
            </div>
          </div>
          <div className="row">
            <div className="container-input">
              <p className={telError ? 'error' : ''}>Telefone *</p>
              <input
                type="text"
                value={tel}
                placeholder="(DDD) + Telefone ..."
                className={telError ? 'error' : ''}
                onChange={(e) => setTel(e.target.value)}
                ref={inputTelRef}
              />
            </div>
            <div className="container-input">
              <p className={emailError ? 'error' : ''}>Email *</p>
              <input
                type="email"
                value={email}
                className={emailError ? 'error' : ''}
                placeholder="Email ..."
                onChange={(e) => setEmail(e.target.value)}
                ref={inputEmailRef}
              />
            </div>
          </div>
        </div>
        <p className="msg-error">
          {msgErrorDados}
        </p>
      </div>
      <div className="dados container-column endereco">
        <h3>Preencha seu endereço</h3>
        <div className="form container-column">
          <div className="row">
            <div className="container-input">
              <p className={cpfError ? 'error' : ''}>CEP *</p>
              <input
                type="name"
                value={cep}
                onChange={(e) => setCep(e.target.value)}
                placeholder="CEP"
                className={cpfError ? 'error' : ''}
                ref={inputCepRef}
              />
            </div>
          </div>
          <div className="row">
            <div className="container-input">
              <p className={ruaError ? 'error' : ''}>Endereço *</p>
              <input
                type="name"
                value={rua}
                onChange={(e) => setRua(e.target.value)}
                placeholder="Endereço"
                className={ruaError ? 'error' : ''}
                ref={inputRuaRef}
              />
            </div>
            <div className="container-input">
              <p className={numError ? 'error' : ''}>Número *</p>
              <input
                type="name"
                value={num}
                onChange={(e) => setNum(e.target.value)}
                placeholder="Número"
                className={numError ? 'error' : ''}
                ref={inputNumRef}
              />
            </div>
            <div className="container-input">
              <p className={bairroError ? 'error' : ''}>Bairro *</p>
              <input
                type="name"
                value={bairro}
                onChange={(e) => setBairro(e.target.value)}
                placeholder="Bairro"
                className={bairroError ? 'error' : ''}
                ref={inputBairroRef}
              />
            </div>
          </div>
          <div className="row">
            <div className="container-input">
              <p className={cidadeError ? 'error' : ''}>Cidade *</p>
              <input
                type="text"
                value={cidade}
                placeholder="Cidade"
                className={cidadeError ? 'error' : ''}
                onChange={(e) => setCidade(e.target.value)}
                ref={inputCidadeRef}
              />
            </div>
            <div className="container-input">
              <p className={ufError ? 'error' : ''}>UF *</p>
              <select
                value={uf}
                className={ufError ? 'error' : ''}
                onChange={(e) => setUf(e.target.value)}
                ref={inputUfref}
              >
                <option value="">UF</option>
                <option value="AC">AC</option>
                <option value="AL">AL</option>
                <option value="AP">AP</option>
                <option value="AM">AM</option>
                <option value="BA">BA</option>
                <option value="CE">CE</option>
                <option value="DF">DF</option>
                <option value="ES">ES</option>
                <option value="GO">GO</option>
                <option value="MA">MA</option>
                <option value="MT">MT</option>
                <option value="MS">MG</option>
                <option value="PA">PA</option>
                <option value="PB">PB</option>
                <option value="PR">PR</option>
                <option value="PI">PI</option>
                <option value="RJ">RJ</option>
                <option value="RN">RN</option>
                <option value="RS">RS</option>
                <option value="RO">RO</option>
                <option value="RR">RR</option>
                <option value="SC">SC</option>
                <option value="SP">SP</option>
                <option value="SE">SE</option>
                <option value="TO">TO</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FormDadosUsuario;
