function verificarTaxa(numVezes) {
  if (numVezes === 12) {
    return 0.33;
  } if (numVezes === 11) {
    return 0.3135;
  } if (numVezes === 10) {
    return 0.297;
  } if (numVezes === 9) {
    return 0.288;
  } if (numVezes === 8) {
    return 0.2792;
  } if (numVezes === 7) {
    return 0.273;
  } if (numVezes === 6) {
    return 0.264;
  } if (numVezes === 5) {
    return 0.2525;
  } if (numVezes === 4) {
    return 0.24;
  }
  return 0;
}

export default function simular(valor, vezes) {
  const taxa = verificarTaxa(vezes);
  let servico;
  if (valor < 800) {
    servico = 10;
  } else {
    servico = 17;
  }

  const parcela = Number((((Number.parseFloat(valor) * taxa) + servico)
                  + Number.parseFloat(valor)) / vezes).toFixed(2);
  return parcela;
}
